body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Lato', sans-serif;
}

img{
  width :100%;
  height : 600px;

  @media (max-width : 480px) {
    width : 100%;
    height : 200px;
  }
}
.profile-img{
  width :60px;
  height : 60px;
  border-radius : 50%
}
.rentah-logo{
  width :100px;
  height : 100px;
  margin-left : 120px;
  @media (max-width : 480px) {
    margin-left : 120px;
  }
}


#map-container{
  width : 100%;
  height : 600px;
  margin-top : 20px;
  @media (max-width : 480px) {
    width : 100%;
    height : 200px;
  }
}
.modal-content{
  border-radius : 10px;
  @media (max-width : 480px) {
    height : 500px;
  }
}
.header{
  background  :black;
  color : green;
  height : 60px;
  font-size : 30px
}
.rentah-logo-header{
  width :50px;
  height : 50px;
  margin-top : 5px;
  margin-left : 50px;
  @media (max-width : 480px) {
    margin-left : 80px;
  }
}

.image-container {
  position: absolute;
  top:150px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background:  conic-gradient(#00a0a0 0deg 252deg, transparent 252deg);
  padding: 3px;
  
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid white;
}
